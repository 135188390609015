import {
    Box,
    chakra,
    Container,
    Flex,
    Image,
    Link,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from "@chakra-ui/react";
import useTranslation from "hooks/useTranslation";
import {Link as ReachLink} from "react-router-dom";
import CcHubLogo from "../assets/img/cchub-logo-white.svg";
import {
    FacebookIcon,
    InstaIcon,
    LinkedInIcon,
    Logo,
    TwitterIcon,
} from "./icons";

const SocialButton = ({children, label, href}) => {
    return (
        <chakra.button
            rounded={"full"}
            w={8}
            h={8}
            cursor={"pointer"}
            as={"a"}
            href={href}
            target='_blank'
            display={"inline-flex"}
            alignItems={"center"}
            justifyContent={"center"}
            transition={"background 0.3s ease"}
            _hover={{
                bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const ListHeader = ({children}) => {
    return (
        <Text fontWeight={"500"} fontSize={"lg"} mb={2} color="#FFFFFF">
            {children}
        </Text>
    );
};

export default function Footer() {
    return (
        <Box
            width="100%"
            style={{
                borderTop: "1px solid rgba(21, 21, 21, 0.04)",
            }}
            bg="#0F0F10"
        >
            <Container as={Stack} maxW="1440px" py={12} position="relative"
                       px={{base: "20px", lg: "80px", xl: "140px"}}>
                <Flex flexDirection={{base: "column", lg: "row"}} gap="30px">
                    <Stack spacing={6}>
                        <Box>
                            <ReachLink to="/">
                                <Logo color={useColorModeValue("gray.700", "white")}/>
                            </ReachLink>
                        </Box>
                        <Text
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            fontWeight={400}
                        >
                            {useTranslation("Creative Economy Practice at CcHUB")}
                        </Text>
                        <Text
                            color={"#FFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            fontWeight={400}
                            maxWidth={200}
                        >
                            294 Herbert Macaulay Way, Sabo, Yaba, Lagos, Nigeria
                        </Text>
                    </Stack>

                    <Spacer/>
                    <Stack align="start" spacing="20px">
                        <ListHeader>{useTranslation("Quick Links")}</ListHeader>
                        <Link
                            as={ReachLink}
                            to={"/#aboutUs"}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            {useTranslation("What we Do")}
                        </Link>
                        <Link
                            as={ReachLink}
                            to={"/#community"}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            {useTranslation("Expert Community")}
                        </Link>
                        <Link
                            as={ReachLink}
                            to={"/#programmes "}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            {useTranslation("Programmes")}
                        </Link>
                        <Link
                            href={"https://www.creativevibrancyindex.africa/en-US"}
                            target={"_blank"}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            CVIA
                        </Link>
                        <Link
                            href={"https://curatedatthecreative.substack.com/"}
                            target={"_blank"}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            {useTranslation("Blog")}
                        </Link>
                        <Link
                            as={ReachLink}
                            to={"/policy"}
                            color={"#FFFFFF"}
                            fontFamily={"DM Sans, sans-serif"}
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                        >
                            {useTranslation("Privacy Policy")}
                        </Link>
                        <Link
                            as={ReachLink}
                            to={"/sub-sector-results"}
                            color="#FFFFFF"
                            fontFamily="DM Sans, sans-serif"
                            fontSize={15}
                            lineHeight="19.53px"
                            fontWeight={400}
                            cursor="pointer"
                        >
                            {useTranslation("Sub-sector Results")}
                        </Link>

                    </Stack>
                    <Spacer/>

                    <Stack align="start">
                        <Flex justify={{base: "start"}}>
                            <Flex justify={{sm: "start", lg: "start"}} width={"100%"}>
                                <Stack align={"flex-start"}>
                                    <Text color="#FFFFFF" opacity="92%" fontWeight={500} fontSize="20px" mb="24px">Feel
                                        free to Reach out</Text>
                                    <Link
                                        as={ReachLink}
                                        to={"mailto:info@cchubcreativeeconomy.africa"}
                                        color={"#FFFFFF"}
                                        fontFamily={"DM Sans, sans-serif"}
                                        fontSize={15}
                                        fontWeight={400}
                                        mt={0}
                                    >
                                        info@cchubcreativeeconomy.africa
                                    </Link>
                                    <Stack direction={"row"} spacing={6} pt={2}>
                                        <SocialButton label={"Twitter"} href={"https://twitter.com/CcHUBCreative"}>
                                            <TwitterIcon/>
                                        </SocialButton>
                                        <SocialButton label={"Facebook"}
                                                      href={"https://www.facebook.com/CcHUBCreative/"}>
                                            <FacebookIcon/>
                                        </SocialButton>
                                        <SocialButton label={"LinkedIn"}
                                                      href={"https://ng.linkedin.com/showcase/cchubcreativeeconomy/"}>
                                            <LinkedInIcon/>
                                        </SocialButton>
                                        <SocialButton label={"Instagram"}
                                                      href={"https://www.instagram.com/cchubcreative/"}>
                                            <InstaIcon/>
                                        </SocialButton>
                                    </Stack>
                                </Stack>
                            </Flex>
                        </Flex>
                        <Stack direction={"column"}>
                            <Text
                                color={"#FFFFFF"}
                                fontFamily={"DM Sans, sans-serif"}
                                fontSize={15}
                                fontWeight={400}
                                textAlign={"left"}
                            >
                                {useTranslation("Powered by")}
                            </Text>
                            <Link
                                as={null}
                                href='https://cchub.africa/'
                                isExternal={true}
                            >
                                <Image src={CcHubLogo} width={180}/>
                            </Link>
                        </Stack>
                    </Stack>
                </Flex>
            </Container>
        </Box>
    );
}
